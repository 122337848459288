<script setup>
const localePath = useLocalePath()
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  context: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: true,
  },
  images: {
    type: Array,
    required: true,
  },
  urlDownload: {
    type: String,
    required: true,
  },
})

function generateFileNameFromURL(url) {
  // if (!url) return props.title

  // const lastSlashIndex = url.lastIndexOf('/')
  // const fileNameWithQuery = url.substring(lastSlashIndex + 1)
  // const fileNameWithoutQuery = fileNameWithQuery.split('?')[0]
  // const timestamp = new Date().getTime()
  // const finalFileName = `${fileNameWithoutQuery}_${timestamp}`
  // return finalFileName
  if (!url) return props.title

  const lastSlashIndex = url.lastIndexOf('/')
  const fileNameWithQuery = url.substring(lastSlashIndex + 1)
  const fileNameWithoutQuery = fileNameWithQuery.split('?')[0]
  return fileNameWithoutQuery
}
</script>

<template>
  <div class="card-resource">
    <NuxtLink
      :to="props.urlDownload"
      :download="generateFileNameFromURL(props.urlDownload)"
      target="_blank"
      class="card-link sensible-more">
      <div class="card-top">
        <SharedBasicImage
          :assets="props.images"
          :alternative="props.title"
          :animate="false"
          class="card-image" />
        <div class="card-category">{{ props.type }} | {{ props.size }}</div>
        <div class="icon-link-btn btn btn--round-small btn--round-light">
          <svg class="icon icon--btn-round-small icon--rotate-90">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </div>
      </div>
      <div class="card-bottom">
        <div class="card-date">
          {{ props.date }}
        </div>
        <h3 class="card-title">
          {{ props.title }}
        </h3>
        <div class="card-context">
          {{ props.context }}
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss">
.card-resource {
  height: 100%;
  margin-bottom: pxrem(30);

  @include mq(md) {
    margin-bottom: 0;
  }

  &:hover {
    .card-image {
      img {
        transform: scale(1.025);
      }
    }
  }

  .card-link {
    cursor: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  .card-top {
    margin-bottom: pxrem(20);
    position: relative;
  }

  .card-image {
    overflow: hidden;
    position: relative;

    &::before {
      background: linear-gradient(180deg, transparent 0%, $color-blue 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 40%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 1;
    }

    img {
      aspect-ratio: 1.6;
      transition: transform $transition-default;
      width: 100%;
      will-change: transition;
    }
  }

  .card-category {
    @include label($color-white);
    bottom: pxrem(15);
    left: pxrem(15);
    position: absolute;
    text-transform: uppercase;
    z-index: 1;

    @include mq(md) {
      bottom: pxrem(20);
      left: pxrem(20);
    }
  }

  .icon-link-btn {
    bottom: pxrem(15);
    position: absolute;
    right: pxrem(15);
    z-index: 1;

    @include mq(md) {
      bottom: pxrem(20);
      right: pxrem(20);
    }
  }

  .card-date {
    @include paragraph;
    margin-bottom: pxrem(10);
  }

  .card-title {
    @include display-3;
    font-weight: 700;
  }

  .card-context {
    @include display-3;
  }
}
</style>
